// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

// Infobox - use to call out information.  Choose a type to help communicate the severity of the message:
// info (default style), warning, success, error, teach
//
// Base classes: layout and content
// scss-lint:disable ImportantRule

.infobox {
  word-break: break-word;
  hyphens: none;
  position: relative;
  min-height: 18px; // prevents vertical jitter if content is generated by JS
  padding: 12px 18px 12px 48px !important; // important used to override common use of infobox for confirmation dialogs

  &:before {
    width: 36px;
  }

  &.infobox-no-icon {
    padding-left: 24px !important;

    :before {
      display: none;
    }
  }

  >.icon {
    position: absolute;
    top: 13px;
    left: 9px;
  }

  ul {
    margin-right: 0;
    margin-left: 27px;
  }

  + .infobox,
  + h2 {
    margin-top: 18px;
  }

  // scss-lint:disable SelectorFormat
  .two_col_left {
    width: 80%;
  }
  
  .two_col_right {
    width: 19%;
    margin-left: 1%;
    padding-left: 0;

    .button-primary {
      width: 100% !important;
      float: right;
      box-sizing: border-box;
    }
  }
}

.infobox[style='display: none;'] + .infobox,
.infobox[style='display:none;'] + .infobox {
  margin-top: 0;
}

.infobox h2,
.infobox h3,
.infobox h2 *,
.infobox h3 * {
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.infobox h2 span,
.infobox h3 span {
  color: $text-color-dark;
}

.infobox h2 + *,
.infobox h3 + *,
.infobox p + *,
.infobox ul + *,
.infobox ol + *,
.infobox li + * {
  margin-top: 9px;
}

//
// Infoboxes with icons (recommended over no icon version)
//

// Shared styles for all icon infoboxes

.infobox,
.infobox-md {
  border: $border-default;
  border-radius: 3px;
  background-color: $component-bg-color-light;
}

.infobox:before,
.infobox-md:before,
.infobox-compact:before {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  content: '';
}

// infobox - 24x24 icon
.infobox-md {
  min-height: 18px; // prevents vertical jitter if content is generated by JS
  padding: 24px 24px 24px 70px !important; // important used to override common use of infobox for confirmation dialogs

  h2,
  h3 {
    font-size: 15px;

    * {
      margin: 0;
      line-height: 18px;
    }
  }

  &:before {
    width: 48px;
  }

  >.icon {
    top: 20px;
    left: 11px;
  }

  + .button-bar.button-bar-attached {
    width: auto;
    padding-left: 60px;
    float: none;

    &.button-bar-l-height {
      line-height: 32px;
    }
  }

}

//
// Infobox Compact -- use when space is severely constrained, 16x16 is recommended
//

.infobox-compact {
  min-height: 16px; // if using icon only, make sure the height accounts for icon height
  padding: 9px 9px 9px 40px !important; // important used to override common use of infobox for confirmation dialogs
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: $border-color-default;
  background-color: $component-bg-color-light;

  p,
  li {
    line-height: 16px;
  }

  h2 + *,
  h3 + *,
  p + *,
  ul + *,
  ol + *,
  li + * {
    margin-top: 5px;
  }

  &:before {
    width: 30px;
  }

  + p {
    margin-top: 9px;
  }

  >.icon {
    top: 9px;
    left: 6px;
  }
}

// Infobox - 48x48 icon (teach only)
// Big banner used to explain important features, should link to doc, often associated with a tutorial video.
// Only used for teaching callout at this time.
.infobox-xl {
  min-height: 18px; // prevents vertical jitter if content is generated by JS
  padding: 26px 36px 26px 108px !important;

  &:before {
    width: 84px;
  }

  >.icon {
    top: 22px;
    left: 18px;
  }

  p,
  ul {
    width: 75%;
    margin-top: 20px;
  }

  p,
  li {
    font-size: 14px;
    line-height: 20px;
  }

  h2,
  h3 {
    font-size: 18px;

    * {
      margin: 0;
      line-height: 18px;
    }
  }
}


// Infobox teach
// The only supported 48x48 infobox. All teaching infoboxes should be dismissible//
.infobox-teach {
  h2,
  h3 {
    color: $btn-bg-color-teach;
    font-weight: 200;
  }

  &.infobox-dismiss .button-teach {
    margin-top: 20px;
    float: right;
  }
}

// Dismissible infobox
// Use for transient messages that can be dismissed by user
.infobox-dismiss {
  padding-right: 30px;
}

.infobox-dismiss-link {
  display: block;
  position: absolute;
  top: 10px;
  right: 8px;
  color: $text-color-dark;
  line-height: 14px;
  cursor: pointer;
}

.infobox-dismiss-link:hover {
  text-decoration: none;
}

.help-wrap .infobox.infobox-dismiss .infobox-dismiss-link {
  top: 0;
  right: 0;
}

// Used by JS to toggle display 
.infobox-dismiss-hide {
  display: none !important;
}


// Confirmation infobox
// Short message that confirms save processing, success or failure. Positioned at the top of the app.  Should be
// dismissible by user if not automatically timed out by JS

.infobox-confirm {
  position: absolute !important;
  top: 20px;
  left: 50%;
  width: 240px;
  margin-left: -154px;
  box-shadow: 0 2px 0 rgba(160, 160, 160, 0.1);
  z-index: 10;
}

.infobox-confirm-fixed {
  position: fixed !important;
  top: 106px;

  .infobox-dismiss-link {
    top: 14px;
    right: 14px;
  }
}


//
// Icons and themes
//

// Message type colors.  Note: without one of these modifiers the tip will be blue by default.
.infobox:before {
  background-color: $primary-color;
}

.infobox.infobox-subtle.infobox-success:before,
.help-wrap .infobox.infobox-success:before,
.infobox-success:before {
  background-color: $secondary-color;
}

.infobox.infobox-subtle.infobox-warning:before,
.help-wrap .infobox.infobox-warning:before,
.infobox-warning:before {
  background-color: $icon-color-warning;
}

.infobox.infobox-subtle.infobox-error:before,
.help-wrap .infobox.infobox-error:before,
.infobox-error:before {
  background-color: $error-color;
}

.infobox.infobox-subtle.infobox-tip:before,
.infobox.infobox-subtle.infobox-teach:before,
.help-wrap .infobox.infobox-tip:before,
.help-wrap .infobox.infobox-teach:before,
.infobox-tip:before,
.infobox-teach:before {
  background-color: $teach-color;
}

// Infobox icons and spinners
.infobox-warning .icon.processing-16 {
  width: 16px;
  height: 16px;
  background: url('../img/ui/indicators/ajax-loader-processing-16.gif') no-repeat 0 0 transparent;
}

.infobox-warning .icon.processing-24 {
  width: 24px;
  height: 24px;
  background: url('../img/ui/indicators/ajax-loader-processing-24.gif') no-repeat 0 0 transparent;
}

.infobox-error .icon.processing-16 {
  width: 16px;
  height: 16px;
  background: url('../img/icons/16x16/error-01.png') no-repeat 0 0 transparent;
}

// Infobox + button bars
.infobox + .button-bar,
.infobox + .button-bar-small {
  border-top: 0;
}

.infobox-no-border + .button-bar,
.infobox-no-border + .button-bar-small {
  border-top: 1px solid $border-bottom-color-default;
}

// Overrides
.infobox-no-border {
  border: none !important;
}

.infobox-text {
  padding: 0;
}

.infobox-header {
  font-weight: bold;
}

.infobox-divider {
  border-bottom: 1px dotted $border-color-default;
}

// Context specific variants

// Infobox overrides

.edit-form .infobox,
.read-only-form .infobox,
.ajax-form-editor .infobox,
.form-content-wrap .infobox,
.o-form .infobox,
.m-form .infobox,
.v-form .infobox {
  background-color: $white;
}

.infobox.infobox-subtle,
.help-wrap .infobox {
  padding: 0 0 0 18px !important;
  border: 0;
  background: transparent;

  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 6px;
    border-radius: 0;
    background-color: $primary-color;
    content: '';
  }

  .icon {
    display: none;
  }
}
