.siw-main-view {
  .device-challenge-poll {
    .o-form-fieldset-container {
      text-align: center;
      margin: 0 auto;

      .spinner {
        height: 38px;
        margin-top: 40px;
        background: url('../img/ui/indicators/loader@1x.gif') no-repeat center;
      }

      .loopback-content,
      .credential-sso-extension {
        margin-bottom: 24px;
      }

      .universal-link-content {
        .spinner {
          margin: 0 0 15px;
        }
      }

      div + div,
      p + p {
        margin-top: 10px;
      }
    }

    .o-form-content {
      .okta-form-subtitle {
        font-weight: 600;
        text-align: center;
      }
    }
    .skinny-content {
      width: 88%;
      margin: 20px auto 0;

      .link {
        color: $primary-color;
      }
    }
    .ul-button {
      margin-top: 20px;
    }
    .highlight-text {
      font-weight: bold;
    }
    .app-link-content {
      margin-bottom: 15px;

      .spinner {
        margin-bottom: 30px;
      }
    }
    .hide.button.link-button {
      display: none;
    }
  }
}



