.forgot-password-sms-enabled,
.forgot-password-call-enabled {

  .sms-hint,
  .mobile-recovery-hint {
    font-size: $font-size-small;
    padding-bottom: 10px;
    position: relative;
    top: -5px;
  }

  .o-form-fieldset-container {
    overflow: hidden;

    .button {
      width: 100%;
      float: none;
      margin-left: 0;
      margin-bottom: 15px;
    }

    .button + .button-primary {
      margin-left: 0;
    }

  }

}

.recovery-challenge {
  .enroll-sms-phone-send {
    @include max-width-mq($container-width) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .o-form-fieldset-container {
    display: flex;
    flex-direction: row-reverse;
  }

  .sms-request-button {
    margin-left: 5px;
  }

  .o-form-fieldset.enroll-sms-phone {
    // Need to verify what the correct dimensions should be for this
    // to match up with other forms
    width: 178px;

    @include device-mq(x-small) {
      width: 150px;
    }

    @include max-width-mq($container-width) {
      width: 100%;
    }
  }

  .o-form-button-bar {
    padding-bottom: 15px;
  }

  .send-email-link {
    display: inline-block;
    font-size: $font-size-small;
    padding-bottom: 25px;
    text-align: center;
    width: 100%;
  }
}
