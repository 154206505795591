// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

@mixin rgba($property, $color: $black, $opacity: 0.75, $mix: $white) {
  #{$property}: mix($color, $mix, $opacity * 100%);
  #{$property}: rgba($color, $opacity);
}

@mixin border-rgba($color: $black, $opacity: 0.75, $property-suffix: null, $mix: $white) {
  @if ($property-suffix == null) {
    border: 1px solid mix($color, $mix, $opacity * 100%);
    border: 1px solid rgba($color, $opacity);
  } @else {
    border-#{$property-suffix}: 1px solid mix($color, $mix, $opacity * 100%);
    border-#{$property-suffix}: 1px solid rgba($color, $opacity);
  }
}

@mixin light-button-template($bg-color) {
  @include button-template($bg-color, 1%, $dark-text-color, 22%, 25%);
  @include button-shadow(0.05);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin dark-button-template($bg-color) {
  @include button-template($bg-color, 3%, #fff, 15%, 17%);
  @include button-shadow(0.15);
  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    box-shadow: none;
  }
}

@mixin button-shadow($drop-shadow-opacity) {
  box-shadow: rgba(#000, $drop-shadow-opacity) 0 1px 0, rgba(255, 255, 255, 0.1) 0 1px 0 0 inset;
}

@mixin button-template($bg-color, $gradient-factor, $text-color, $border-factor, $border-bottom-factor) {
  color: $text-color;
  background-color: $bg-color;
  background: -o-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $bg-color 0%, darken($bg-color, $gradient-factor) 100%); /* IE10+ */
  background: linear-gradient($bg-color, darken($bg-color, $gradient-factor));
  border-color: darken($bg-color, $border-factor);
  border-bottom-color: darken($bg-color, $border-bottom-factor);

  &:hover,
  &:focus,
  &:active,
  &:focus {
    /* -- Buttons' Colors -- */
    $hover-bg-color: lighten($bg-color, 3%);
    background-color: $hover-bg-color;
    background: -o-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, $hover-bg-color 0%, darken($hover-bg-color, $gradient-factor) 100%); /* IE10+ */
    background-image: linear-gradient($hover-bg-color, darken($hover-bg-color, $gradient-factor));
    border-color: darken($bg-color, $border-factor * 1.3);
    border-bottom-color: darken($bg-color, $border-bottom-factor * 1.2);
    text-decoration: none;
    cursor: pointer;
  }
}
