.infobox.icon-16 > .icon {
  position: absolute;
}

.infobox.icon-16 {
  margin-bottom: 15px;
}

.contact-support .infobox {
  margin-bottom: 15px;
}

.infobox > .icon {
  line-height: 16px;
}
