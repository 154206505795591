.sign-in-with-device-option,
.sign-in-with-webauthn-option {
  .okta-verify-container,
  .okta-webauthn-container {
    text-align: center;
  }
}

.sign-in-with-idp,
.custom-buttons {
  .okta-verify-container {
    .button {
      text-align: center;
    }
  }
}

.sign-in-with-idp {
  .okta-idps-container {
    .social-auth-button.link-button {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.sign-in-with-device-option,
.sign-in-with-webauthn-option,
.sign-in-with-idp,
.custom-buttons {
  .okta-verify-container,
  .okta-webauthn-container {
    .signin-with-ov-description {
      margin-bottom: 0.83em;
    }
    .button {
      display: block;
      position: relative;
      min-height: 50px;
      height: auto;
      padding-top: 15px;
      padding-bottom: 10px;
      line-height: 1.4;
      align-items: center;
    }
  }
  .separation-line {
    position: relative;
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;

    &:before {
      font-size: 13px;
      border-top: 1px solid $border-color-default;
      content: "";
      margin: 0 auto;
      position: absolute;
      top: 55%;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      z-index: -1;
    }

    span {
      background: $white;
      padding: 0 15px;
      font-size: 13px;
      color: $medium-text-color;
    }
  }
}

.o-form-button-bar {
  .links-primary {
    font-size: $font-size-small;
    margin-top: 1.9em;
    margin-bottom: -1em;
    max-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: column;
    text-align: left;
    .link {
      padding: 0.3rem 0;
      word-wrap: break-word;
    }
  }
}
