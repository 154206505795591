.siw-main-view {
  .infobox-warning,
  .infobox-error {
    display: block;
    margin-bottom: 15px;
  }

  .okta-form-subtitle {
    margin: 25px 0 10px;
    text-align: left;
  }

  .siw-main-body {
    .o-form-content {
      overflow: unset; // prevents beacon from jumping on form save via .o-form-saving
    }
    .ion-messages-container {
      text-align: center;
      margin-bottom: 1rem;
      > * {
        margin-top: 1rem;
      }
    }

    .o-form-content .o-form-label {
      padding-right: 0;

      .o-form-explain {
        float: right;
      }
    }

    .identifier-container {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
      position: relative;
      top: -5px;
      margin-bottom: 15px;
    }
    .identifier {
      font-size: $body-font-size;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .identifier-container::before {
      content: url('../img/icons/identifier/user-icon.svg');
      vertical-align: -20%;
      padding-right: 4px;
    }
  }

  // Fixing OKTA-303139 here, making sure V1 stays the same.
  .custom-checkbox {
    width: max-content;
    label {
      line-height: inherit;
    }
  }

  .o-form-explain.o-form-input-error {
    word-break: normal; // Change word-break for M1 to default value OKTA-345397
  }

}
