.oie-enroll-google-authenticator {
  .google-authenticator-setup-info-title {
    text-align: center;
    margin-bottom: 15px;
  }

  .qrcode-info-container {
    display: flex;
  }

  .google-authenticator-setup-info {
    margin-right: 1rem;
  }

  .qrcode-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;

    .qrcode {
      display: block;
      border: $border-default;
      border-radius: $border-radius-default;
      max-width: 150px;
      margin-bottom: 5px;
    }
  }

  .google-authenticator-next {
    &.default-custom-button {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }

  .shared-secret {
    .o-form-input {
      width: 100%;

      input {
        text-align: center;
      }
    }
  }
}
