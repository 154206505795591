.auth-account {
  line-height: 18px;
  padding: 6px 0;
}

.auth-content {
  padding: 60px 42px 20px;

  -moz-transition: padding-top 0.4s;
  -webkit-transition: padding-top 0.4s;
  transition: padding-top 0.4s;

  @include device-mq(x-small) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include max-width-mq($container-width - 50px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .auth-content-inner {
    position: relative;
  }

  .help-links {
    li {
      margin-top: 15px;
    }
  }

}

.no-beacon .auth-content {
  padding-top: 20px;
}

.auth-idp {
  background-color: #f9f9f9;
  border-radius: 0 0 3px 3px;
  padding: 20px 30px;
  border-top: 1px solid #ddd;
}

.auth-idp-label {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 200;
  line-height: 30px;
  color: #888;
  float: left;
  margin-right: 155px;
}

.auth-divider {
  background-color: #a7a7a7;
  height: 1px;
  margin-bottom: 25px;
  text-align: center;

  .auth-divider-text {
    background-color: #fff;
    position: relative;
    top: -0.5em;
    padding: 0 15px;
  }
}

// Transition classes
.animation-container-overflow {
  overflow: hidden;
}

.transition-from-right {
  position: absolute;
  width: 316px;
  top: 0;
  left: 200px;
  opacity: 0;
}

.transition-from-left {
  position: absolute;
  width: 316px;
  top: 0;
  left: -200px;
  opacity: 0;
}
