/* stylelint-disable selector-max-id */
#okta-sign-in {
  // Html and body styles in _base.scss that were moved here
  font-family: $fonts;
  font-size: $font-size;
  font-weight: $font-weight-body;
  line-height: 1.4;
  background-color: $secondary-bg-color;
  color: $medium-text-color;
  position: relative;
  overflow: auto;

  // Auth-container styles
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  height: auto;
  margin: 100px auto 8px;
  width: $container-width;
  min-width: $container-min-width;

  &:focus {
    outline: none;
  }

  @include device-mq(x-small) {
    margin-top: 0;
    width: 100%;
  }

  @include max-width-mq($container-width) {
    width: 100%;
  }

  @include max-height-mq(750px) {
    margin-top: 0;
  }
}

#okta-sign-in.no-beacon .auth-content {
  padding-top: 20px;
}

#okta-sign-in.no-beacon .auth-header {
  padding-bottom: 30px;
}

