// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

// Okta icon colors
$icon-color-dark: #5e5e5e;
$icon-color-light: #8c8c96;
$icon-color-medium: #666;
$icon-color-white: $white;
$icon-color-black: $black;
$icon-color-green: $secondary-color;
$icon-color-light-green: #b0d244;
$icon-color-danger: $error-color;
$icon-color-warning: $warning-color;
$icon-color-info: $primary-color;
$icon-color-super-light: #e0e0e0;

// Social icon colors
$icon-color-facebook: #3b5998;
$icon-color-google: #dd4b39;

// Operating System icon
$icon-color-android: #92bf43;
$icon-color-windows: #00abef;
