.siw-main-header {
  position: relative;
  .beacon-container {
    top: -183px;
    z-index: 10;
    .auth-beacon-factor {
      cursor: initial;
    }
  }
}
