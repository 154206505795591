.auth {
  .content {
    min-height: 100%;
    min-width: $container-min-width;
    display: inline-block;
    width: 100%;

    &:after {
      content: '';
      display: block;
      height: 30px;
    }
  }

  .footer {
    background-color: #fff;
    min-width: $container-min-width;
    position: absolute;
    font-size: $font-size-footer;
    left: 0;
    right: 0;
    bottom: 0;
    height: 34px;
    overflow: hidden;

    .footer-container {
      padding: 9px 80px;
      margin: 0 auto;
      box-sizing: border-box;

      @include max-width-mq(560px) {
        width: $container-width;
        padding-left: 20px;
        padding-right: 20px;
      }

      @include max-width-mq($container-width) {
        width: 100%;
      }
    }

    a {
      color: $medium-text-color;

      &:link,
      &:visited,
      &:active {
        text-decoration: none;
        color: $medium-text-color;
      }

      &:hover,
      &:focus {
        text-decoration: underline;
        color: $medium-text-color;
      }
    }

    .copyright {
      float: left;
    }

    .privacy-policy {
      float: right;
    }
  }
}
