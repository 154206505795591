.password-authenticator {
  &--heading {
    margin-bottom: 1rem;
  }

  &--list {
    margin-bottom: 1rem;
    padding-left: 1rem;
    list-style: outside;
  }
}
