.auth-footer {
  font-size: $font-size-small;

  .js-factor-page-custom-link {
    display: block;
  }

  .goto {
    float: right;
  }

  .dot-separator {
    font-weight: bold;
  }
}

.auth-footer.footer-back-link {
  margin-top: 0.75em;
}

