.siw-main-footer {
  .auth-footer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    .link {
      width: fit-content;
      padding: 0.3rem 0;
      word-wrap: break-word;
    }

    .help-description {
      .ov-enrollment-info {
        list-style: decimal;
        margin: 15px 0 15px 15px;
      }
    }

    &.side-by-side-links {
      display: flex;
      flex-direction: row;

      .link {
        flex-basis: 50%;

        &.js-skip-setup {
          order: 2;
          text-align: right;
          color: $primary-color;
        }
      }
    }

    .privacy-footer {
      p {
        margin-top: 20px;
      }
    }
  }

  .footer-info {
    border-top: 1px solid $border-color-default;
    padding-top: 1rem;
    margin-top: 1rem;
    display: flex;

    .signup-info {
      margin: 0 auto;

      span {
        padding: 0.3rem 0;
      }

      .signup-link {
        padding-left: 0.3rem;

        .link {
          color: $primary-color;
        }
      }
    }
  }
}
