.password-requirements--header {
  padding-bottom: 10px;
}

.password-requirements--list {
  list-style: initial;
  padding-left: 20px;
  padding-bottom: 10px;
}

.password-requirements--list-item {
  line-height: 1.5 * $font-size;
}
