// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

@use "sass:math";

// Okta colors
$primary-color: #007cc0;
$primary-border-color: #005380;
$primary-accent-color: #026ca4;
$primary-accent-border-color: #01486e;
$link-color: #0074b3;

$primary-medium-color: #46b3e9;
$primary-medium-border-color: #1b90c5;
$primary-light-color: #a9e6fd;

$secondary-color: #4cbf9c;
$secondary-color-text: #46b090;
$secondary-color-border: #338069;
$secondary-color-gradient-darker: #49b897;

$warning-color: #f1d10d;
$error-color: #e34843;
$teach-color: #835ab1;

// Text
$body-font-family: 'montserrat-okta', Arial, Helvetica, sans-serif;
$body-font-size: 13px;
$small-font-size: 12px;
$code-font-family: 'anonymous-pro', 'Andale Mono', 'Courier New', Courier, monospace;
$text-color-dark: #5e5e5e;
$text-color-light: #a7a7a7;
$text-color-darker: #444;
$text-color-placeholder: #a1a1a1;
$paragraph-line-height: 18px;
$component-heading-color: #919191;
$form-heading-color: $secondary-color-text;
$paragraph-margin: math.div($paragraph-line-height, 2);
$text-color-warning: #966603;

// Buttons
$btn-bg-color-primary: $secondary-color;
$btn-bg-color-default: #fefefe;
$btn-bg-color-danger: #e24545;
$btn-bg-color-teach: $teach-color;

// Tabs
$tab-bg-color: transparent;
$tab-border-color: #dadada;
$tab-border-color-active: $secondary-color;

// Background colors
$component-bg-color: #f9f9f9;
$component-bg-color-light: #fbfbfb;
$component-bg-color-dark: #f2f2f2;
$item-count-bg-color: #cacaca;

// Borders
$border-color-default: #ddd;
$border-color-light: #eee;
$border-bottom-color-default: #dadada;
$border-default: 1px solid $border-color-default;
$border-radius-default: 3px;
$border-radius-top-left-default: 3px;
$border-radius-top-right-default: 3px;
$border-radius-bottom-left-default: 3px;
$border-radius-bottom-right-default: 3px;

// Box shadow
$box-shadow-default: 0 2px 0 rgba(175, 175, 175, 0.12);

// Common colors
$black: #000;
$white: #fff;
$red: #f00;


