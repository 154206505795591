// Generated from okta-core sha: 900f683
// Note: Do not modify this file directly.

.facebook-square-16:before {
  color: $icon-color-facebook;
  content: $facebook-square;
}

.facebook-16:before {
  color: $icon-color-facebook;
  content: $facebook;
}

.google-square-16:before {
  color: $icon-color-google;
  content: $google-square;
}

.google-16:before {
  color: $icon-color-google;
  content: $google;
}

.twitter-square-16:before {
  color: $icon-color-dark;
  content: $twitter-square;
}

.twitter-16:before {
  color: $icon-color-dark;
  content: $twitter;
}

.linkedin-square-16:before {
  color: $icon-color-dark;
  content: $linkedin-square;
}

.linkedin-16:before {
  color: $icon-color-dark;
  content: $linkedin;
}

.openid-16:before {
  color: $icon-color-dark;
  content: $openid;
}
