.siw-main-view {
  .resend-email-view {
    margin-bottom: 15px;
    text-align: center;

    .infobox {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}
