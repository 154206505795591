.enduser-email-consent {
  .o-form-button-bar {
    display: flex;
  }
  &--info {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center;
  }
  &--icon {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    background-size: cover;
  }
}

.enduser-email-otp-only {
  .o-form-button-bar {
    display: flex;
  }
  &--info {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    align-items: center;
  }
  &--icon {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    background-size: cover;
  }
}

.enter-code-on-page {
  font-size: small;
  margin-bottom: 1.5rem;
  text-align: center;
}

.otp-value {
  font-size: x-large;
  text-align: center;
  margin-bottom: 1rem;
}

.otp-warning {
  color: $light-text-color;
  text-align: center;
}
